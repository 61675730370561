<template>
  <div>
    <div id="printMe" class="report-container" @click="showBtn=true">
      <h1 class="center">{{title}}</h1>
      <div class="d-flex justify-between mb-2">
        <div class="d-flex">
          <div class="flex-shrink ">卡片编号:</div>
          <el-input v-model="cardNo" class="input-border-bottom "></el-input>
        </div>
        <div class="d-flex">
          <div>报卡类型：</div>
          <div>1、初次报告</div>
          <div>2、订正报告</div>
        </div>
      </div>
      <div class="report-content">
        <div class="section">
          <div class="row-item d-flex">
            <div class="d-flex">
              <div class="flex-shrink">患者姓名：</div>
              <el-input class="input-border-bottom center" v-model="patient.name" type="text"></el-input>
            </div>
            <div class="d-flex">
              <div class="ml-1 flex-shrink">(患儿家长姓名：</div>
              <el-input class="input-border-bottom center" v-model="patient.fname"></el-input>
              <div>)
              </div>
            </div>
          </div>
          <div class="row-item d-flex ">
            <div class="d-flex">
              <div class="flex-shrink">身份证号：</div>
              <div id="id-card" v-for="(n, index) in idCard" :key="index">
                <el-input @input="idCard[index].length > 1 ? idCard[index] = idCard[index].slice(0,1): idCard[index]" class="input-number" maxlength="1" :key="index" type="number" v-model="idCard[index]"></el-input>
              </div>
            </div>
            <div class="d-flex">
              <div class="ml-1">性别*：</div>
              <el-radio-group v-model="patient.gender">
                <el-radio v-for="item in genders" :key="item.label" :label="item.label">{{item.value}}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="row-item d-flex">
            <div class="d-flex">
              <div class="flex-shrink">出生日期*：</div>
              <el-input class="input-border-bottom medium center" v-model="b_year" type="number"></el-input>年
              <el-input class="input-border-bottom small center" v-model="b_month" type="number"></el-input>月
              <el-input class="input-border-bottom small center" v-model="b_day" type="number"></el-input>日
            </div>
            <div class="d-flex">
              <div class="flex-shrink">（如出生日期不详，实足年龄：</div>
              <el-input class="input-border-bottom small center" v-model="patient.age" type="number"></el-input>
            </div>
            <div class="d-flex">
              <div class="flex-shrink">年龄单位：</div>
              <el-radio-group v-model="computedAgeUnit">
                <el-radio v-for="(unit,index) in ageUnit" :key="index" :label="unit">{{unit}}</el-radio>
              </el-radio-group>
              ）
            </div>
          </div>
          <div class="row-item d-flex">
            <div class="d-flex">
              <div class="flex-shrink">工作单位：</div>
              <el-input style="width:24rem" class="input-border-bottom center" v-model="work_address"></el-input>
            </div>
            <div class="d-flex">
              <div class="flex-shrink">联系电话：</div>
              <el-input v-model="patient.patient_phone" class="input-border-bottom center"></el-input>
            </div>
          </div>
          <div class="row-item d-flex">
            <div class="d-flex">
              <div class="flex-shrink">病人属于*：</div>
              <el-radio-group v-model="belong">
                <el-radio v-for="item in comeFrom" :key="item" :label="item">{{item}}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="row-item d-flex">
            <div class="d-flex">
              <div class="flex-shrink">现住址（详填）*：</div>
              <el-input v-model="local.province" style="width:5rem" class="input-border-bottom center"></el-input>省
              <el-input v-model="local.city" style="width:5rem" class="input-border-bottom center"></el-input>市
              <el-input v-model="local.area" style="width:5rem" class="input-border-bottom center medium"></el-input>县（区）
              <el-input v-model="local.street" class="input-border-bottom center medium"></el-input>乡（镇，街道）
              <el-input v-model="local.village" class="input-border-bottom center medium"></el-input>村
              <el-input v-model="local.number" class="input-border-bottom center medium"></el-input>（门牌号）
            </div>
          </div>
          <div class="row-item d-flex">
            <div class="flex-shrink">患者职业*：</div>
            <el-radio-group v-model="major">
              <el-radio v-for="item in professional" :key="item" :label="item">{{item}}</el-radio>
            </el-radio-group>
          </div>
          <div class="row-item d-flex">
            <div class="flex-shrink">
              病例分类*：
            </div>
            <div>
              <div class="d-flex">（1）
                <el-radio-group v-model="sicknessTypeOne">
                  <el-radio v-for="item in categoryOne" :key="item" :label="item">{{item}}</el-radio>
                </el-radio-group>
              </div>
              <div class="d-flex">（2）
                <el-radio-group v-model="sicknessTypeTwo">
                  <el-radio v-for="item in categoryTwo" :key="item" :label="item">{{item}}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="row-item d-flex">
            <div class="flex-shrink">发病日期*：</div>
            <el-input class="input-border-bottom medium center" v-model="patient.inquiry_date_y" type="number"></el-input>年
            <el-input class="input-border-bottom small center" v-model="patient.inquiry_date_m" type="number"></el-input>月
            <el-input class="input-border-bottom small center" v-model="patient.inquiry_date_d" type="number"></el-input>
            <div class="flex-shrink">日（病原体携带者填初检日期或就诊日期）</div>
          </div>
          <div class="row-item d-flex">
            <div class="flex-shrink">诊断日期*：</div>
            <el-input v-model="diagnose_y" class="input-border-bottom center medium" type="number"></el-input>年
            <el-input v-model="diagnose_m" class="input-border-bottom center small" type="number"></el-input>月
            <el-input v-model="diagnose_d" class="input-border-bottom center small" type="number"></el-input>日
            <el-input v-model="diagnose_h" class="input-border-bottom center small" type="number"></el-input>时
          </div>
          <div class="row-item d-flex">
            <div class="flex-shrink">死亡日期：</div>
            <el-input v-model="dead_y" class="input-border-bottom center medium" type="number"></el-input>年
            <el-input v-model="dead_m" class="input-border-bottom center small" type="number"></el-input>月
            <el-input v-model="dead_d" class="input-border-bottom center small" type="number"></el-input>日
          </div>
        </div>
        <div class="section">
          <div class="row-item flex-shrink">甲类传染病*：</div>
          <el-radio-group v-model="sicknessA">
            <el-radio v-for="item in infectiousA" :key="item" :label="item">{{item}}</el-radio>
          </el-radio-group>
        </div>
        <div class="section">
          <div class="row-item flex-shrink">乙类传染病*：</div>
          <el-radio-group v-model="sicknessB">
            <el-radio v-for="item in infectiousB" :key="item" :label="item">{{item}}</el-radio>
          </el-radio-group>
        </div>
        <div class="section">
          <div class="row-item flex-shrink">丙类传染病*：</div>
          <el-radio-group v-model="sicknessC">
            <el-radio v-for="item in infectiousC" :key="item" :label="item">{{item}}</el-radio>
          </el-radio-group>
        </div>
        <div class="section">
          <div class="row-item flex-shrink">其他法定管理以及重点监测传染病：</div>
          <el-input style="text-align:left" v-model="other" class="input-border-bottom"></el-input>
        </div>
        <div class="section">
          <div class="row-item d-flex justify-between">
            <div class="d-flex">
              <div class="flex-shrink">订正病名：</div>
              <el-input v-model="sicknessName" class="input-border-bottom large"></el-input>
            </div>
            <div class="d-flex">
              <div class="flex-shrink">退卡原因：</div>
              <el-input v-model="reason" class="input-border-bottom large"></el-input>
            </div>
          </div>
          <div class="row-item d-flex justify-between">
            <div class="d-flex">
              <div class="flex-shrink">报告单位：</div>
              <el-input v-model="patient.hospital" class="input-border-bottom large"></el-input>
            </div>
            <div class="d-flex">
              <div class="flex-shrink">联系电话：</div>
              <el-input v-model="telephone" class="input-border-bottom large"></el-input>
            </div>
          </div>
          <div class="row-item d-flex justify-between">
            <div class="d-flex">
              <div class="flex-shrink">报告医生：</div>
              <el-input v-model="patient.doctor" class="input-border-bottom large"></el-input>
            </div>
            <div class="d-flex">
              <div class="input-border-bottom flex-shrink">填卡日期*：</div>
              <el-input v-model="fillDate.y" class="input-border-bottom medium center" type="number"></el-input>年
              <el-input v-model="fillDate.m" class="input-border-bottom small center" type="number"></el-input>月
              <el-input v-model="fillDate.d" class="input-border-bottom small center" type="number"></el-input>日
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-button v-if="showBtn" class="my-3 ml-3 center" type="success" @click="printContext">打印</el-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import API from '@/utils/api'
export default {

  data () {
    return {
      showBtn: true,
      title: '中华人民共和国传染病报告卡',
      idCard: new Array(18),
      genders: [{ label: 1, value: '男' }, { label: 0, value: '女' }],
      radio: '',
      ageUnit: ['年', '月', '天'],
      comeFrom: ['本县区', '本市其他区县', '本省其他城市', '外省', '港澳台', '外籍'],
      address: ['省', '市', '县（区）', '乡（镇，街道）', '村', '门牌号'],
      professional: ['幼托儿童、', '散居儿童、', '学生(大中小学)、', '教师、', '保育员及保姆、', '餐饮食品业、', '商业服务、'
        , '医务人员、', '工人、', '民工、', '农名、', '牧民、', '渔（船）民、', '干部职员、', '离退人员、', '家务及待业、', `其他（${'　　　'}）、`, '不详'],
      categoryOne: ['疑似病例、', '临床诊断病例、', '实验室确证病例、', '病原携带者'],
      categoryTwo: ['急性、', '慢性（乙型肝炎、血吸虫病填写）'],
      infectiousA: ['鼠疫、', '霍乱'],
      infectiousB: ['传染性非典型肺炎、', '艾滋病、病毒性肝炎（', '甲型、', '乙型、', '丙型、', '成型、', '未分型、', '脊髓灰质炎、', '人感染高致病性禽流感、', '甲型H1N1流感、', '麻疹、', '流行性出血热、',
        '狂犬病、', '流行性乙型脑炎、', '登革热、', '炭疽（', '肺炭疽、', '皮肤炭疽、', '未分型）、痢疾（', '细菌性、', '阿米巴性、', '肺结核、', '涂阳、', '仅培阳、', '菌阴、', '未痰检）、伤寒（', '伤寒、', '副伤寒、', '流行性脑脊髓膜炎、', '百日咳、', '白喉、',
        '新生儿破伤风、', '猩红热、', '布鲁氏菌病、', '淋病、梅毒', 'Ⅰ期、', 'Ⅱ期、', 'Ⅲ期、', '胎传、', '隐性、', '钩端螺旋体病、', '血吸虫病、疟疾（', '间日疟、', '恶性疟、', '未分型）'
      ],
      infectiousC: ['流行性感冒、', '流行性腮腺炎、', '风疹、', '急性出血性结膜炎、', '麻风病、', '流行性和地方性斑疹伤寒、', '黑热病、', '包虫病、', '丝虫病、', '手足口病、', '除霍乱、细菌性和阿米巴性痢疾、伤寒和副伤寒以外的感染性腹泻病。'],
      from: '',
      gender: '',
      age: '',
      belong: '',
      major: '',
      sicknessTypeOne: '',
      sicknessTypeTwo: '',
      sicknessA: '',
      sicknessB: '',
      sicknessC: '',
      sicknessName: '',
      patient: {},
      computedAgeUnit: '',
      other: '',
      cardNo: '',
      reason: '',
      department: '',
      doctor: '',
      telephone: '',
      dead_y: '',
      dead_m: '',
      dead_d: '',
      diagnose_y: '',
      diagnose_m: '',
      diagnose_d: '',
      diagnose_h: '',
      y: '',
      m: '',
      d: '',
      b_year: '',
      b_month: '',
      b_day: '',
      local: {
        province: '',
        city: '',
        street: '',
        area: '',
        village: '',
        number: ''
      },
      work_address: '',
      fillDate: {
        y: new Date().getFullYear(),
        m: new Date().getMonth() + 1,
        d: new Date().getDate()
      }
    }
  },

  async mounted () {
    let res = await API.getHealthRecord(this.$route.params.id)
    if (res.status === 200 && res.result.length) {
      this.patient = res.result[0]
      let userInfo = await API.getUserInfo()
      if (userInfo.status === 200) {
        this.patient.hospital = userInfo.result[0].hospital_name
      }
      this.patient.gender = res.result[0].gender
      this.patient.inquiry_date_y = new Date(this.patient.inquiry_date).getFullYear()
      this.patient.inquiry_date_m = new Date(this.patient.inquiry_date).getMonth() + 1
      this.patient.inquiry_date_d = new Date(this.patient.inquiry_date).getDate()
      this.diagnose_y = new Date(this.patient.inquiry_date).getFullYear()
      this.diagnose_m = new Date(this.patient.inquiry_date).getMonth() + 1
      this.diagnose_d = new Date(this.patient.inquiry_date).getDate()
      this.diagnose_h = new Date(this.patient.inquiry_date).getHours()
      let ages = this.patient.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
      let ageYear = parseInt(ages[0]),
        ageMonths = parseInt(ages[1].substring(0, 2)),
        ageDays = parseInt(ages[1].substring(2, 4))
      ageYear = ageYear > 0 ? ageYear : ''
      ageMonths = ageMonths > 0 ? ageMonths : ''
      ageDays = ageDays > 0 ? ageDays : ''
      this.patient.age = ageYear ? ageYear : ageMonths ? ageMonths : ageDays
      this.computedAgeUnit = ageYear ? '年' : ageMonths ? '月' : '日'
    }
  },

  computed: {
    ...mapState('index', ['userInfo'])
  },

  methods: {
    ...mapActions('inquiry', ['getHealthRecord']),
    printContext () {
      this.showBtn = false
      setTimeout(() => {
        window.print()
      }, 200);
    }
  }
}
</script>
<style lang="less">
* {
  color: #000;
  font-size: 16px;
  line-height: 20px;
}
.large {
  width: 14.7rem;
}
.medium {
  width: 6rem;
}
.small {
  width: 3rem;
}
.is-checked {
  color: #000 !important;
}
.d-flex {
  align-items: center;
}
.flex-shrink {
  flex-shrink: 0;
}
.justify-between {
  justify-content: space-between;
}
.row-item {
  margin-bottom: 12px;
}
.el-radio {
  margin-right: 0px;
  .el-radio__inner {
    width: 21px;
    height: 21px;
    border-radius: 0;
  }
}
.align-ceter {
  text-align: center;
}
.el-radio__input.is-checked .el-radio__inner {
  background: #000;
}
.report-container {
  .center {
    input {
      text-align: center;
    }
  }
  padding: 18px;
  label {
    margin-bottom: 0px;
  }
  input {
    font-size: 16px;
  }
  .section {
    padding: 18px;
    border-bottom: 1px solid #000;
  }
  .input-border-bottom input {
    border: 0px;
    border-radius: 0px;
    padding-right: 0px;
    border-bottom: 1px solid black;
    height: 28px;
    line-height: 0px;
  }
  .el-input__inner {
    padding: 0;
  }
  .input-number {
    .el-input__inner {
      width: 2rem;
      height: 2rem;
      text-align: center;
    }
  }
  .center {
    text-align: center;
  }
  .report-content {
    // padding: 18px;
    border: 1px solid #000;
  }
  /* 谷歌 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  /* 火狐 */
  input {
    -moz-appearance: textfield;
  }
}
.el-radio__input.is-checked + .el-radio__label {
  color: #000;
}
.el-radio__input.is-checked .el-radio__inner::after {
  transform: translate(-140%, -180%) scale(1);
}
.el-radio__inner::after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  display: block;
  content: '√' !important;
  text-align: center;
  font-size: 16px;
  color: black;
}
.el-radio__input.is-checked .el-radio__inner {
  background: #fff;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #dcdfe6;
  background: #fff;
}
</style>